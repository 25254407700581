exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-multiple-article-article-js": () => import("./../../../src/templates/multiple/article/article.js" /* webpackChunkName: "component---src-templates-multiple-article-article-js" */),
  "component---src-templates-multiple-contact-contact-js": () => import("./../../../src/templates/multiple/contact/contact.js" /* webpackChunkName: "component---src-templates-multiple-contact-contact-js" */),
  "component---src-templates-multiple-default-default-js": () => import("./../../../src/templates/multiple/default/default.js" /* webpackChunkName: "component---src-templates-multiple-default-default-js" */),
  "component---src-templates-multiple-footer-cookies-js": () => import("./../../../src/templates/multiple/footer/cookies.js" /* webpackChunkName: "component---src-templates-multiple-footer-cookies-js" */),
  "component---src-templates-multiple-footer-page-footer-js": () => import("./../../../src/templates/multiple/footer/page-footer.js" /* webpackChunkName: "component---src-templates-multiple-footer-page-footer-js" */),
  "component---src-templates-multiple-group-biography-biography-js": () => import("./../../../src/templates/multiple/group/biography/biography.js" /* webpackChunkName: "component---src-templates-multiple-group-biography-biography-js" */),
  "component---src-templates-multiple-implantation-implantation-detail-js": () => import("./../../../src/templates/multiple/implantation/implantation-detail.js" /* webpackChunkName: "component---src-templates-multiple-implantation-implantation-detail-js" */),
  "component---src-templates-single-contact-index-js": () => import("./../../../src/templates/single/contact/index.js" /* webpackChunkName: "component---src-templates-single-contact-index-js" */),
  "component---src-templates-single-homepage-homepage-js": () => import("./../../../src/templates/single/homepage/homepage.js" /* webpackChunkName: "component---src-templates-single-homepage-homepage-js" */),
  "component---src-templates-single-implantations-implantation-js": () => import("./../../../src/templates/single/implantations/implantation.js" /* webpackChunkName: "component---src-templates-single-implantations-implantation-js" */),
  "component---src-templates-single-media-media-js": () => import("./../../../src/templates/single/media/media.js" /* webpackChunkName: "component---src-templates-single-media-media-js" */),
  "component---src-templates-single-pdf-index-js": () => import("./../../../src/templates/single/pdf/index.js" /* webpackChunkName: "component---src-templates-single-pdf-index-js" */)
}

